import React, { useState } from "react";
import styled from "styled-components";
import sendIcon from "./assets/send-arrow.svg";
import FeatureButton from "./FeatureButton"; // Assuming this is the correct path for FeatureButton
import { Button } from "@mantine/core";
import { IconBulb } from "@tabler/icons-react";
import SaveComponent from "../components/Startups/SaveComponent";
import GoogleDrivePickerAndUploader from "../components/Lib/FilesPickerAndUploader";

// Styled components
const ChatDocumentUploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background-color: #fff;
`;

const AttachedDocuments = styled.div`
  width: 40%;
  height: 100%;
`;

const SectionTitle = styled.p`
  margin-bottom: 8px;
  font-weight: var(--font-weight-h3);
  font-size: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 24px;
`;

const ChatArea = styled.div`
  width: 60%;
`;

const ChatBox = styled.div`
  height: 250px;
  border: 1px solid var(--color-navigation-icon-dark);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
`;

const ChatMessages = styled.div`
  padding: 5px;
  overflow-y: auto;
  flex-grow: 1;
`;

const ChatMessage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  flex-direction: ${(props) =>
    props.issent === "true" ? "row-reverse" : "row"};
`;

const ChatBubble = styled.div`
  background-color: ${(props) =>
    props.issent === "true" ? "#f1f1f1" : "var(--color-button-accent)"};
  padding: 8px;
  border-radius: 12px;
  max-width: 70%;
  font-size: 0.85rem; /* Reduced font size */
  border: ${(props) => (props.issent === "true" ? "1px solid #ddd" : "none")};

  p {
    color: ${(props) =>
      props.issent === "true" ? "#333" : "white"} !important;
  }
`;

const ChatText = styled.p`
  color: #333;
  margin: 0; /* Remove any margin */
`;

const ChatInputArea = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-top: 1px solid #ddd;
`;

const ChatInput = styled.input`
  flex-grow: 1;
  padding: 8px;
  border: 1px solid var(--color-button-accent);
  border-radius: 8px;
  margin-right: 5px;
  font-size: 0.85rem;
  outline: none;
`;

const SendButton = styled.button`
  background-color: var(--color-button-accent);
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SendIcon = styled.img`
  width: 16px;
  &:hover {
    filter: brightness(0.8);
  }
`;

// Component
const ChatDocumentUpload = ({  close }) => {
  const [chats, setChats] = useState([]);
  const [userMessage, setUserMessage] = useState("");

  // Mock function to generate AI response based on user input
  // Fetch a dummy AI response from Bacon Ipsum API
  const fetchDummyResponse = async () => {
    try {
      const response = await fetch(
        'https://baconipsum.com/api/?type=meat-and-filler&sentences=1'
      );
      const data = await response.json();
      return data[0]; // The first sentence in the response
    } catch (error) {
      console.error("Failed to fetch AI response", error);
      return "Sorry, something went wrong. Please try again.";
    }
  };

  const handleSendMessage = async() => {
    if (!userMessage.trim()) return; // Don't send empty messages

    const userChat = { message: userMessage, issent: true };
    setChats([...chats, userChat]); // Add user message to the chat

    // Generate a mock AI response
    const aiResponse = await fetchDummyResponse();
    const aiChat = { message: aiResponse, issent: false };

    // Simulate some delay (optional)
    setTimeout(() => {
      setChats((prevChats) => [...prevChats, aiChat]); // Add AI response to the chat
    }, 500);

    setUserMessage(""); // Clear input field
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <ChatDocumentUploadContainer>
      {/* Attached Documents */}
      <AttachedDocuments>
        <SectionTitle>Attached Documents</SectionTitle>
        <GoogleDrivePickerAndUploader />

        {/* Buttons Below Attached Documents */}
        <Button
          variant="default"
          fullWidth
          className="flex-grow-0 mt-4 font-light rounded-lg border border-transparent bg-green-800 text-white hover:bg-green-600 disabled:opacity-50 disabled:pointer-events-none expert"
          leftIcon={<IconBulb size="1.5rem" />}
        >
          Validate your response
        </Button>
        <ButtonContainer>
          <FeatureButton action={close} buttonText="Discard" />
          <SaveComponent close={close} />
        </ButtonContainer>
      </AttachedDocuments>

      {/* Chat Area */}
      <ChatArea>
        <SectionTitle>Smart AI Chat</SectionTitle>
        <ChatBox>
          <ChatMessages>
            {chats.map((chat, index) => (
              <ChatMessage key={index} issent={chat.issent + ""}>
                <ChatBubble issent={chat.issent + ""}>
                  <ChatText>{chat.message}</ChatText>
                </ChatBubble>
              </ChatMessage>
            ))}
          </ChatMessages>

          {/* Chat Input with Send Icon */}
          <ChatInputArea>
            <ChatInput
              type="text"
              placeholder="Type a message"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <SendButton onClick={handleSendMessage}>
              <SendIcon src={sendIcon} alt="Send" />
            </SendButton>
          </ChatInputArea>
        </ChatBox>
      </ChatArea>
    </ChatDocumentUploadContainer>
  );
};

export default ChatDocumentUpload;
