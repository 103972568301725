import React, { useState } from "react";
import styled from "styled-components";
import fetchApi from "../components/Lib/api";

// Styled Components
const UserButtonContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const UserButtonStyled = styled.button`
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const UserInitials = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e5f2a2;
  color: #717945;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
`;

const UserInfo = styled.div`
  flex: 1;
  text-align: left;
`;

const UserName = styled.span`
  display: block;
  color: #48504b;
  font-weight: var(--font-weight-h3);
  font-size: var(--font-size-h4);
`;

const UserEmail = styled.span`
  display: block;
  font-size: 12px;
  color: #888;
`;

const DropdownIcon = styled.span`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const DropdownLink = styled.a`
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
  font-size: 14px;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const DropdownButton = styled.button`
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: #f2f2f2;
  }
`;

export const handleLogout = async () => {
  try {
    const response = await fetchApi("/users/sign_out", "DELETE", {});

    if (response) {
      // Redirect using JavaScript after successful logout
      window.location.href = "/users/sign_in"; // Redirect to the homepage
    } else {
      console.error("Logout error:", "Failed to log out");
    }
  } catch (error) {
    console.error("Logout error:", error.message);
  }
};

const UserButton = ({
  userName = "Harriette Spoonlicker",
  userEmail = "hspoonlicker@outlook.com",
  userImage = null,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getInitials = (name) => {
    const nameArray = name.split(" ");
    const initials = nameArray.map((n) => n[0]).join("");
    return initials.toUpperCase();
  };

  return (
    <UserButtonContainer>
      <UserButtonStyled onClick={toggleDropdown}>
        {userImage ? (
          <Avatar src={userImage} alt="User Avatar" />
        ) : (
          <UserInitials>{getInitials(userName)}</UserInitials>
        )}
        <UserInfoWrapper>
          <UserInfo>
            <UserName>{userName}</UserName>
            <UserEmail>{userEmail}</UserEmail>
          </UserInfo>
          <DropdownIcon>
            {!dropdownOpen ? (
              <svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 4.25L0.25 0.5H7.75L4 4.25Z" fill="#514D45" />
              </svg>
            ) : (
              <svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: "rotate(180deg)" }}
              >
                <path d="M4 4.25L0.25 0.5H7.75L4 4.25Z" fill="#514D45" />
              </svg>
            )}
          </DropdownIcon>
        </UserInfoWrapper>
      </UserButtonStyled>

      {dropdownOpen && (
        <DropdownMenu>
          <DropdownLink href="/profile">Profile</DropdownLink>
          <DropdownLink href="/profile/#/settings">Settings</DropdownLink>
          <DropdownButton onClick={handleLogout}>Sign Out</DropdownButton>
        </DropdownMenu>
      )}
    </UserButtonContainer>
  );
};

export default UserButton;
