import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Loader, Menu } from "@mantine/core";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import fetchApi from "../Lib/api";
import { useParams } from "react-router-dom";
import HeaderAndSidebar from "../Lib/HeaderAndSidebar";
import MainContainer from "../../stories/MainContainer";
import StartupHeader from "../../stories/StartupHeader";
import StartupBody from "../../stories/StartupBody";
import useStartupStore from "../../stores/startupStore";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

const RoadmapHeader = styled.div`
  align-self: flex-start;
`;

const HeaderTitle = styled.h3`
  font-weight: var(--font-weight-h4);
  color: var(--color-background-card);
`;

const HeaderText = styled.p`
  color: #68706b;
  margin-top: 8px;
`;

const MemoStartupShow = memo(StartupShow);

const fetchStartupData = async (slug, setStartup) => {
  const response = await fetchApi(`/startup_entities/${slug}`, "GET");
  if (!response.ok) {
    throw new Error("Failed to fetch startup data");
  }
  const data = await response.json();
  console.log(data);
  setStartup(data);
  return data;
};

export default function StartupContainer({ project }) {
  return (
    <HeaderAndSidebar>
      <MemoStartupShow project={project} />
    </HeaderAndSidebar>
  );
}

export const handleFollowUnfollow = async (
  isFollowing,
  slug,
  setIsFollowing
) => {
  try {
    const endpoint = !isFollowing
      ? `/startup_entities/${slug}/followings`
      : `/startup_entities/${slug}/followings/${slug}`;
    const method = !isFollowing ? "POST" : "DELETE";
    const response = await fetchApi(endpoint, method);

    if (response.ok) {
      const res = await response.json();
      if (setIsFollowing) setIsFollowing(!isFollowing);
    } else {
      const { error } = await response.json();
      console.error(error);
    }
  } catch (error) {
    console.error("Error during follow/unfollow:", error);
  }

  if (!setIsFollowing) return !isFollowing;
};

function StartupShow({ project }) {
  const { slug } = useParams();
  const fileInputRef = useRef(null);

  const {
    setStartup,
    logTime,
    isOwner,
    isMember,
  } = useStartupStore((state) => ({
    setStartup: state.setStartup,
    logTime: state.logTime,
    isOwner: state.isOwner,
    isMember: state.isMember,
  }));


  // Use TanStack Query to fetch startup data and store it in Zustand
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["startup", slug],
    queryFn: () => fetchStartupData(slug, setStartup),
    cacheTime: 1000 * 60 * 5, // keep cache for 5 minutes
  });

  // Log time spent on the page when the component unmounts or page unloads
  useEffect(() => {
    window.addEventListener("beforeunload", () => logTime(slug));

    return () => {
      window.removeEventListener("beforeunload", () => logTime(slug));
      logTime(slug); // Call logTime manually on unmount
    };
  }, [logTime, slug]);

  // Handle image click event
  const handleImageClick = () => {
    if (isMember || isOwner) {
      fileInputRef.current.click();
    }
  };

  // Show loading indicator while the data is being fetched
  if (isLoading) {
    return (
      <Loader
        className="mx-auto mt-10"
        color="indigo"
        size="lg"
        variant="dots"
      />
    );
  }

  // Show error message if there's an error fetching the data
  if (isError) {
    return <p>Error fetching startup data: {error.message}</p>;
  }

  return (
    <MainContainer>
      <StartupHeader />
      <RoadmapHeader>
        <HeaderTitle>Roadmaps</HeaderTitle>
        <HeaderText>
          A short and crisp context about what is roadmap and how to complete.{" "}
          <a href="#" style={{ display: "inline" }}>
            <b>What is it ? Know more about it</b>
          </a>
        </HeaderText>
      </RoadmapHeader>
      <StartupBody />
    </MainContainer>
  );
}

export const StartupPrivacy = ({ startup, setStartup }) => {
  const handleSubmit = async (val) => {
    if (startup.startup_entity.public_listed === val) return;

    try {
      const response = await fetchApi(
        `/startup_entities/${startup.startup_entity.slug}`,
        "PATCH",
        {
          startup_entity: { public_listed: val },
        }
      );

      if (response.ok) {
        const res = (await response.json()).startup_entity;

        setStartup((p) => ({
          ...p,
          startup_entity: {
            ...p.startup_entity,
            public_listed: res.public_listed,
          },
        }));
      } else {
        const msg = (await response.json()).error;
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  return (
    <Menu shadow="md">
      <Menu.Target>
        <Button
          variant="default"
          className="w-fit"
          size="md"
          leftIcon={
            startup.startup_entity.public_listed ? (
              <IconEye size="1.3rem" />
            ) : (
              <IconEyeOff size="1.3rem" />
            )
          }
        >
          Privacy
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <div className="relative flex items-start py-2 px-3 rounded-lg hover:bg-gray-100 ">
          <div className="flex items-center h-5 mt-1">
            <input
              id="public"
              type="radio"
              className="shrink-0 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
              onChange={() => handleSubmit(true)}
              checked={startup.startup_entity.public_listed === true}
            />
          </div>
          <label htmlFor="public" className="ms-3.5">
            <span className="block text-sm font-semibold text-gray-800 dark:text-gray-300">
              Public
            </span>
            <span className="block text-sm text-gray-600 dark:text-gray-500">
              Make it available to everyone.
            </span>
          </label>
        </div>
        <div className="relative flex items-start py-2 px-3 rounded-lg hover:bg-gray-100 ">
          <div className="flex items-center h-5 mt-1">
            <input
              id="private"
              type="radio"
              onChange={() => handleSubmit(false)}
              checked={startup.startup_entity.public_listed === false}
              className="shrink-0 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
            />
          </div>
          <label htmlFor="private" className="ms-3.5">
            <span className="block text-sm font-semibold text-gray-800 ">
              Unlisted
            </span>
            <span className="block text-sm text-gray-600 ">
              Make it private from public view.
            </span>
          </label>
        </div>
      </Menu.Dropdown>
    </Menu>
  );
};
