import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";

import homeIcon from "../../stories/assets/home.svg";
import forumIcon from "../../stories/assets/forum.svg";
import membersIcon from "../../stories/assets/members.svg";
import activityIcon from "../../stories/assets/activity.svg";
import workspaceIcon from "../../stories/assets/workspace.svg";
import profileIcon from "../../stories/assets/profile.svg";

import { rem, Dialog, Rating, Button, Textarea } from "@mantine/core";
import { upperFirst, useDisclosure } from "@mantine/hooks";
import { IconInfoCircle } from "@tabler/icons-react";
import fetchApi from "./api";
import Notification from "./Notification";
import { useTranslation } from "react-i18next";
import Sidebar from "../../stories/Sidebar";
import useUserStore from "../../stores/userStore";

export const handleLogout = async () => {
  try {
    const response = await fetchApi("/users/sign_out", "DELETE", {});

    if (response) {
      // Redirect using JavaScript after successful logout
      window.location.href = "/users/sign_in"; // Redirect to the homepage
    } else {
      console.error("Logout error:", "Failed to log out");
    }
  } catch (error) {
    console.error("Logout error:", error.message);
  }
};

export default function NavbarSimple({ navlinks }) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [active, setActive] = useState(navlinks ? "Profile" : "Home");
  const { user } = useUserStore(
    (state) => state.user
  );

  useEffect(() => {
    const parts = pathname.split("/");
    const lastPart = parts[parts.length - 1];

    setActive(lastPart.length > 3 ? upperFirst(lastPart) : active);
  }, [pathname]);

  return (
    <Sidebar
      startupDetails={{
        startupName: user.full_name,
        subText: user.job_title || "Student",
        startupImage: user?.avatar?.url,
      }}
      navLinks={
        navlinks
          ? { ...navlinks, active: active }
          : {
              links: [
                {
                  name: t("sidebar.home"),
                  icon: homeIcon,
                  href: { hash: "", href: "/dashboard/#/" },
                },
                {
                  name: t("sidebar.forums"),
                  icon: forumIcon,
                  href: { hash: "forums", href: "/dashboard/#/forums" },
                },
                {
                  name: t("sidebar.messages"),
                  icon: forumIcon,
                  href: { hash: "messages", href: "/dashboard/#/messages" },
                },
                {
                  name: t("sidebar.members"),
                  icon: membersIcon,
                  href: { hash: "members", href: "/dashboard/#/members" },
                },
                {
                  name: t("sidebar.activity"),
                  icon: activityIcon,
                  href: { hash: "activity", href: "/dashboard/#/activty" },
                },
                {
                  name: t("sidebar.workspace"),
                  icon: workspaceIcon,
                  href: { hash: "workspace", href: "/dashboard/#/workspace" },
                },
                {
                  name: t("sidebar.profile"),
                  icon: profileIcon,
                  href: { hash: "", href: "/profile/#/" },
                },
              ],
              active: active,
            }
      }
    />
  );
}

const Feedback = ({ user, classes }) => {
  const { t } = useTranslation();
  const [openModal, { open, close }] = useDisclosure(
    !user.user_submit_feedback
  );
  const [desc, setDesc] = useState("");
  const [rating, setRating] = useState(1);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const submitFeedback = async () => {
    if (desc.length < 10 && rating < 4) {
      setError(t("sidebar.short_feedback_error"));
      return;
    }
    setLoading(true);
    try {
      const response = await fetchApi("/feedbacks", "POST", {
        feedback: {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          message: desc || "All Good. No Comment.",
          rating,
        },
      });

      if (response.ok) {
        const res = await response.json();
        setNotification({ type: "success", content: res.message });
        setTimeout(() => close(), 2000);
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: res.error });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }

    setLoading(false);
  };
  return (
    <>
      <Dialog
        opened={openModal}
        position={{ bottom: 20, right: 20 }}
        size="xl"
        radius="md"
        p={0}
      >
        <div className="flex w-full flex-col border rounded-lg bg-white p-4">
          {notification && <Notification {...notification} />}
          <h2 className="title-font mb-1 text-center text-lg font-medium text-gray-900">
            {t("sidebar.rate_experience")}
          </h2>
          <p className="m-2 text-sm text-center leading-relaxed text-gray-600">
            {t("sidebar.feedback_info")}
          </p>
          <div className="mb-1 flex justify-center">
            <Rating
              color="violet"
              size="lg"
              value={rating}
              onChange={setRating}
            />
          </div>
          <div className="mb-2">
            <Textarea
              onChange={(event) => setDesc(event.currentTarget.value)}
              value={desc}
              id="message"
              name="message"
              placeholder={t("sidebar.feedback_message")}
              withAsterisk
              error={error}
            />
          </div>
          <Button
            onClick={submitFeedback}
            loading={loading}
            className="rounded border-0 bg-indigo-500 py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
          >
            {t("sidebar.feedback_submit")}
          </Button>
        </div>
      </Dialog>

      <FooterLink
        onClick={open}
        label={t("sidebar.give_feedback")}
        tooltipLabel="Help"
        icon={
          <IconInfoCircle
            className={classes.linkIcon}
            stroke={2}
            style={{ width: rem(28), height: rem(28) }}
          />
        }
      />
    </>
  );
};
