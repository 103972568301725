import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useUserStore from "../stores/userStore";

// Styled Components
const StartupButtonContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const StartupButtonStyled = styled.button`
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: var(--color-container-card-default);
  color: white;
  padding: 10px 15px;
  border-radius: 8px;
  width: 100%;
`;

const StartupInfoWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;

const Avatar = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 6px;
  margin-right: 10px;
`;

const StartupInfo = styled.div`
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StartupName = styled.h4`
  color: var(--color-background-card);
  font-weight: var(--font-weight-h4);
  line-height: 12px;
`;

const StartupSubtext = styled.p`
  font-size: var(--font-size-body-4);
`;

const DropdownIcon = styled.span`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

const DropdownButton = styled.button`
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const DropdownLink = styled.a`
  display: flex;
  align-items: center;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  width: 100%;

  &:hover {
    background-color: #f2f2f2;
  }
`;

// Startup switching component
const StartupButton = () => {
  const {
    user: {
      startups,
      user: { full_name, avatar },
    },
  } = useUserStore();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeStartup, setActiveStartup] = useState(null);

  // Determine the active startup based on the current URL slug
  useEffect(() => {
    const currentSlug = window.location.pathname.split("/").pop(); // Extract the slug from the URL

    const matchedStartup = startups.find((startup) => startup.slug === currentSlug);
    if (matchedStartup) {
      setActiveStartup(matchedStartup); // Set the matched startup as active
    } else {
      setActiveStartup(startups[0]); // Fallback to the first startup if no match
    }
  }, [startups]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleStartupSwitch = (startup) => {
    setActiveStartup(startup);
    setDropdownOpen(false); // Close the dropdown after selecting
  };

  if (!activeStartup) return null; // Handle cases where no startup is found

  return (
    <StartupButtonContainer>
      <StartupButtonStyled onClick={toggleDropdown}>
        <Avatar
          src={
            avatar?.url ||
            "https://flowbite.com/docs/images/examples/image-3@2x.jpg"
          }
          alt="User Avatar"
        />
        <StartupInfoWrapper>
          <StartupInfo>
            <StartupName>{full_name}</StartupName> {/* User's name remains constant */}
            <StartupSubtext>{activeStartup.name}</StartupSubtext> {/* Show active startup name as subtext */}
          </StartupInfo>
          <DropdownIcon>
            {!dropdownOpen ? (
              <svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 4.25L0.25 0.5H7.75L4 4.25Z" fill="#514D45" />
              </svg>
            ) : (
              <svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: "rotate(180deg)" }}
              >
                <path d="M4 4.25L0.25 0.5H7.75L4 4.25Z" fill="#514D45" />
              </svg>
            )}
          </DropdownIcon>
        </StartupInfoWrapper>
      </StartupButtonStyled>

      {dropdownOpen && (
        <DropdownMenu>
          {startups.map((startup, index) => (
            <DropdownLink
              key={index}
              href={startup.url}
              onClick={() => handleStartupSwitch(startup)}
            >
              <Avatar
                src={
                  startup.image ||
                  "https://flowbite.com/docs/images/examples/image-3@2x.jpg"
                }
                alt="Startup Avatar"
              />
              <div>
                <StartupName>{startup.name}</StartupName> {/* Show startup name in dropdown */}
                <StartupSubtext>{startup.user_role}</StartupSubtext> {/* Display startup role in dropdown */}
              </div>
            </DropdownLink>
          ))}
        </DropdownMenu>
      )}
    </StartupButtonContainer>
  );
};

export default StartupButton;
