import React from "react";
import styled from "styled-components";

// Import logos
import logoImage from './assets/lv.png'; // Replace with the path to your logo
import logoImage2 from './assets/ed.png'; // Replace with the path to your logo
import logoImage3 from './assets/rn.png'; // Replace with the path to your logo
import nc from './assets/nc.jpeg'; // Replace with the path to your logo

// Styled Components for Logo
const LogoContainer = styled.a`
  display: flex;
  flex-direction: column; /* Stack logos vertically */
  align-items: center;
  text-decoration: none;
`;

const LogoImage = styled.img`
  // width: 100px; /* Adjust width as needed */
  height: ${({ height }) => height || 'auto'}; /* Use height prop or default to auto */
  margin-bottom: 5px; /* Space between logos */
`;

const BetaTag = styled.div`
  background: #3d3b4b;
  padding: 2px 4px;
  border-radius: 5px;
  color: var(--color-container-card-default);
  font-family: 'Montserrat', sans-serif;
  font-weight: var(--font-weight-body-1);
  font-size: var(--font-size-body-4);
  height: fit-content;
`;

const Logo = () => {
  return (
    <LogoContainer href="/dashboard/#/">
      <LogoImage src={nc} alt="Logo" height={"25px"} />
      {/* <LogoImage src={logoImage2} alt="Secondary Logo" height={"20px"} /> */}
      {/* <LogoImage src={logoImage3} alt="Secondary Logo" height={"20px"} /> */}
      {/* <LogoImage src={logoImage} alt="Secondary Logo" height={"20px"} /> */}
    </LogoContainer>
  );
};

export default Logo;
