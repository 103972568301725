import React from 'react';
import styled from 'styled-components';

// Styled components for different block types
const Paragraph = styled.p`
  color: #4a4a4a;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1em;
`;

const Header = styled.h1`
  color: #2c3e50;
  margin-bottom: 0.5em;
`;

const List = styled.ul`
  list-style-type: disc;
  margin-left: 1.5em;
  margin-bottom: 1em;
`;

const OrderedList = styled.ol`
  list-style-type: decimal;
  margin-left: 1.5em;
  margin-bottom: 1em;
`;

const ListItem = styled.li`
  margin-bottom: 0.5em;
`;

const ImageWrapper = styled.figure`
  margin: 1em 0;
  text-align: center;
`;

const Image = styled.img`
  max-width: 100%;
  border-radius: 8px;
`;

const Figcaption = styled.figcaption`
  margin-top: 0.5em;
  color: #7f8c8d;
  font-size: 0.9em;
`;

const CodeBlock = styled.pre`
  background-color: #f8f7fa;
  color: #41314e;
  padding: 1em;
  border-radius: 8px;
  overflow-x: auto;
  margin-bottom: 1em;
  font-family: 'Courier New', Courier, monospace;
`;

const EmbedWrapper = styled.div`
  margin: 1em 0;
  text-align: center;
`;

const LinkPreview = styled.div`
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 1em;
  text-decoration: none;
  color: inherit;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const LinkImage = styled.div`
  flex: none;
  width: 120px;
  background-size: cover;
  background-position: center;
`;

const LinkContent = styled.div`
  padding: 1em;
  flex: 1;
`;

const LinkTitle = styled.h5`
  margin: 0 0 0.5em 0;
  font-size: 1em;
  color: #2c3e50;
`;

const LinkDescription = styled.p`
  margin: 0 0 1em 0;
  font-size: 0.9em;
  color: #7f8c8d;
`;

const LinkButton = styled.a`
  color: #3498db;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: #2980b9;
  }
`;

const FileAttachment = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  padding: 1em;
  border-radius: 8px;
  margin-bottom: 1em;
`;

const FileIcon = styled.div`
  flex: none;
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.bgColor || '#aa2284'};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 1em;
  font-weight: bold;
`;

const FileInfo = styled.div`
  flex: 1;
`;

const FileTitle = styled.div`
  font-size: 1em;
  margin-bottom: 0.5em;
`;

const FileSize = styled.div`
  font-size: 0.9em;
  color: #7f8c8d;
`;

const DownloadButton = styled.a`
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
  margin-left: auto;

  &:hover {
    color: #2980b9;
  }
`;

const EditorContentDisplay = ({ content }) => {
  let parsedContent;

  // Parse the content JSON
  if (typeof content === 'string') {
    try {
      parsedContent = JSON.parse(content);
    } catch (e) {
      console.error('Invalid JSON content', e);
      return null;
    }
  } else {
    parsedContent = content;
  }

  const renderBlock = (block, index) => {
    switch (block.type) {
      case 'paragraph':
        return (
          <Paragraph
            key={index}
            dangerouslySetInnerHTML={{ __html: block.data.text }}
          />
        );

      case 'header':
        const HeaderTag = `h${block.data.level}`;
        const headerStyles = {
          1: { fontSize: '2em' },
          2: { fontSize: '1.75em' },
          3: { fontSize: '1.5em' },
          4: { fontSize: '1.25em' },
          5: { fontSize: '1em' },
          6: { fontSize: '0.875em' },
        };

        return (
          <HeaderTag
            key={index}
            style={headerStyles[block.data.level]}
            dangerouslySetInnerHTML={{ __html: block.data.text }}
          />
        );

      case 'list':
        const ListTag = block.data.style === 'ordered' ? OrderedList : List;
        return (
          <ListTag key={index}>
            {block.data.items.map((item, idx) => (
              <ListItem
                key={idx}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            ))}
          </ListTag>
        );

      case 'image':
        return (
          <ImageWrapper key={index}>
            <Image src={block.data.file.url} alt={block.data.caption || ''} />
            {block.data.caption && (
              <Figcaption>{block.data.caption}</Figcaption>
            )}
          </ImageWrapper>
        );

      case 'code':
        return (
          <CodeBlock key={index}>{block.data.code}</CodeBlock>
        );

      case 'embed':
        return (
          <EmbedWrapper key={index}>
            <iframe
              src={block.data.embed}
              width={block.data.width || '100%'}
              height={block.data.height || '400'}
              frameBorder="0"
              allowFullScreen
              title={block.data.caption || 'Embedded content'}
            ></iframe>
            {block.data.caption && (
              <Figcaption>{block.data.caption}</Figcaption>
            )}
          </EmbedWrapper>
        );

      case 'linkTool':
        const data = block.data;
        const meta = data.meta;
        return (
          <LinkPreview key={index} as="a" href={data.link} target="_blank" rel="noopener noreferrer">
            <LinkImage
              style={{ backgroundImage: `url(${meta.image?.url})` }}
              alt={meta.title}
            />
            <LinkContent>
              <LinkTitle>{meta.title}</LinkTitle>
              <LinkDescription>{meta.description}</LinkDescription>
              <LinkButton href={data.link} target="_blank" rel="noopener noreferrer">
                Visit Website
              </LinkButton>
            </LinkContent>
          </LinkPreview>
        );

      case 'attaches':
        const fileData = block.data.file;
        const title = block.data.title || fileData.name;
        const extensionColor = '#aa2284'; // Customize based on file type if needed
        const fileSize = (fileData.size / 1024).toFixed(1); // Size in KiB

        return (
          <FileAttachment key={index}>
            <FileIcon bgColor={extensionColor}>
              {fileData.extension.toUpperCase()}
            </FileIcon>
            <FileInfo>
              <FileTitle>{title}</FileTitle>
              <FileSize>{fileSize} KiB</FileSize>
            </FileInfo>
            <DownloadButton href={fileData.url} target="_blank" rel="noopener noreferrer">
              Download
            </DownloadButton>
          </FileAttachment>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      {parsedContent?.blocks.map((block, index) => renderBlock(block, index))}
    </div>
  );
};

export default EditorContentDisplay;
