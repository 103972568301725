import React from "react";
import styled from "styled-components";
import moneyBagIcon from "./assets/money-bag.svg";

const PitchContainer = styled.div`
  background-color: #ffe86f;
  padding: 15px;
  padding-left: 0px;
  border-radius: 6px;
  text-align: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  max-width: 240px;
`;

const PitchIcon = styled.div`
  img {
    display: block;
    max-width: unset;
  }
`;

const PitchContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
`;

const PitchHeading = styled.h3`
  font-weight: var(--font-weight-h4);
  color: var(--color-background-card);
  line-height: 21px;
  margin-bottom: 6px;
`;

const PitchText = styled.p`
  color: var(--color-background-main);
`;

const PitchButton = styled.button`
  background-color: var(--color-container-card-default);
  color: #ddd;
  padding: 6px 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: not-allowed;
  transition: background-color 0.3s;
  font-weight: var(--font-weight-h4);

  // &:hover {
  //   background-color: var(--color-button-accent);
  //   color: var(--color-container-card-default);
  // }
`;

const PitchComponent = () => {
  return (
    <PitchContainer>
      <PitchIcon>
        <img src={moneyBagIcon} alt="Money Bag Icon" />
      </PitchIcon>
      <PitchContent>
        <div>
          <PitchHeading>Generate Fund Request</PitchHeading>
          <PitchText>Pitch short content for the investors goes here.</PitchText>
        </div>
        <PitchButton>Generate Pitch</PitchButton>
      </PitchContent>
    </PitchContainer>
  );
};

export default PitchComponent;
