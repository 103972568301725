import React, { useState } from "react";
import Calendar from "react-calendar";
import styled from "styled-components";
import arrowDownIcon from "./assets/arrow-down.svg";
import ContainerButton from "./ContainerButton";
import useStartupStore from "../stores/startupStore";

const PackageSelectorContainer = styled.div`
  background-color: var(--color-container-card-default);
  padding: 40px 20px;
  border: 1px solid #dee8e7;
  border-radius: 10px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Title = styled.h3`
  font-weight: var(--font-weight-h4);
  color: var(--color-background-card);
  margin-bottom: 8px;
`;

const Description = styled.p`
  color: var(--color-background-main);
`;

const StepArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Packages = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 24px;
`;

const PackageBox = styled.div`
  width: 30%;
  padding: 20px;
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  background-color: ${({ bgcolor }) => bgcolor};
  text-align: left;
  color: ${({ color }) => color};

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  &.active {
    border: 2px solid var(--color-button-accent);
  }
`;

const PackageName = styled.h4`
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;

  &.active {
    color: var(--color-button-accent);
  }
`;

const PackageInfo = styled.p`
  font-size: 12px;
  color: #777;
`;

const PackageCalendar = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

const PackageSelector = ({ useRoadmapStore }) => {
  const [selectedPackage, setSelectedPackage] = useState(null);

  const { program, setProgram, slug } = useStartupStore((state) => ({
    program: state.program,
    setProgram: state.setProgram,
    slug: state.slug,
  }));

  const { date, setDate, endDate, setEndDate, setDuration, handleUpdate } =
    useRoadmapStore();

  const [value, onChange] = useState(new Date());

  const handlePackageClick = (pkg, d) => {
    setSelectedPackage(pkg);
    setDuration(d);
  };

  return (
    <PackageSelectorContainer>
      <div>
        <Title>Select a Package & set the timeline</Title>
        <Description>
          Choose a plan and set the start and end date of the whole roadmap.
        </Description>
      </div>

      <Packages>
        <PackageBox
          className={selectedPackage === "starter" ? "active" : ""}
          onClick={() => handlePackageClick("starter", 30)}
          bgcolor="#FFF9BA"
          color="#794E0B"
        >
          <PackageName
            className={selectedPackage === "starter" ? "active" : ""}
          >
            Starter
          </PackageName>
          <PackageInfo>
            Suitable for quick projects and short-term goals. Package duration:
            30 days.
          </PackageInfo>
        </PackageBox>

        <PackageBox
          className={selectedPackage === "growth" ? "active" : ""}
          onClick={() => handlePackageClick("growth", 60)}
          bgcolor="#E9FBD3"
          color="#44661A"
        >
          <PackageName className={selectedPackage === "growth" ? "active" : ""}>
            Growth
          </PackageName>
          <PackageInfo>
            Ideal for growing businesses and extended projects. Package
            duration: 60 days.
          </PackageInfo>
        </PackageBox>

        <PackageBox
          className={selectedPackage === "pro" ? "active" : ""}
          onClick={() => handlePackageClick("pro", 100)}
          bgcolor="#E2DBFF"
          color="#4D37A4"
        >
          <PackageName className={selectedPackage === "pro" ? "active" : ""}>
            Pro
          </PackageName>
          <PackageInfo>
            Best for long-term projects with ongoing goals. Package duration:
            100 days.
          </PackageInfo>
        </PackageBox>
      </Packages>

      <PackageCalendar>
        <Calendar onChange={setDate} value={date} />
        <StepArrow>
          <img src={arrowDownIcon} alt="Arrow to next step" />
        </StepArrow>
        <Calendar onChange={setEndDate} value={endDate} tileDisabled={() => !date} minDate={date} />
      </PackageCalendar>

      <div style={{ margin: "auto" }}>
        <ContainerButton
          buttonText="Set Timeline"
          action={() => handleUpdate(slug, setProgram)}
        />
      </div>
    </PackageSelectorContainer>
  );
};

export default PackageSelector;
