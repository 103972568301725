import React, { memo, useState } from "react";
import styled from "styled-components";
import plusIcon from "./assets/add.svg"; // Placeholder for the plus icon
import dropdownIcon from "./assets/dropdown.svg"; // Placeholder for the dropdown icon
import avatarIcon from "./assets/profile.svg";
import FeatureButton from "./FeatureButton";
import ChatComponent from "../components/Startups/StartupChat";
import useStartupStore from "../stores/startupStore";
import ManageRequestToJoinTeam from "../components/Startups/ManageRequestToJoinTeam";
import Meeting from "../components/Startups/Meeting";
import { InviteMembers } from "../components/Startups/InviteMember";

const MemoChatComponent = memo(ChatComponent);

// Styled Components
const StartupMembersContainer = styled.div`
  background-color: var(--color-container-card-default);
  padding: 20px;
  border: 1px solid #dee8e7;
  border-radius: 10px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const FeatureTitle = styled.h3`
  color: var(--color-background-card);
  font-weight: var(--font-weight-h4);
  line-height: 18px;
`;

const MembersList = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 24px;
`;

const MemberAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddMember = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-button-accent);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConnectTeamButton = styled.div`
  display: flex;
  position: relative;
  border-radius: 4px;
`;

const ConnectBtnRight = styled.button`
  padding: 10px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  border: 1px solid var(--color-button-accent);

  &:hover {
    background-color: var(--color-button-accent);
    color: #fff;
  }
`;

const DropdownMenu = styled.ul`
  position: absolute;
  top: 45px;
  right: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DropdownMenuItem = styled.li`
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;

  &:hover {
    background-color: #f0f0f0;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const StartupMembers = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openChat, setOpenChat] = useState(false);

  const { isOwner, isMember, members, slug } = useStartupStore((state) => ({
    slug: state.slug,
    isOwner: state.isOwner,
    isMember: state.isMember,
    members: state.members,
  }));

  console.log(members)

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <StartupMembersContainer>
      <FeatureTitle>Team members ({members.length})</FeatureTitle>
      <MembersList>
        {members.map((member, index) => (
          <MemberAvatar
            key={member.id}
            src={member.avatar_url || avatarIcon}
            alt={`Member ${index + 1}`}
          />
        ))}
        <AddMember>
          <img src={plusIcon} alt="Add Member" />
        </AddMember>
      </MembersList>

      {/* Button divided into two sections */}

      <div style={{ display: "flex", gap: "4px" }}>
        {!(isMember || isOwner) ? (
          <>
            <InviteMembers />
            <Meeting canSchedule={isOwner || isMember} slug={slug} />
          </>
        ) : (
          <>
            <ManageRequestToJoinTeam />
            <ConnectTeamButton>
              <FeatureButton
                buttonText={"Open Chat"}
                hidePlus={true}
                action={() => setOpenChat(true)}
              />
              <ConnectBtnRight onClick={toggleDropdown}>
                <img src={dropdownIcon} alt="Dropdown Icon" />
              </ConnectBtnRight>

              {isDropdownOpen && (
                <DropdownMenu>
                  <InviteMembers />
                  <Meeting canSchedule={isOwner || isMember} slug={slug} />
                </DropdownMenu>
              )}
            </ConnectTeamButton>
          </>
        )}
      </div>
      {(isMember || isOwner) && openChat && (
        <MemoChatComponent startupId={slug} setOpenChat={setOpenChat} />
      )}
    </StartupMembersContainer>
  );
};

export default StartupMembers;
