import React, {  useEffect, useState } from "react";
import NavbarSimple from "./SideBar";
import fetchApi from "./api";
import App from "../../channels/consumer";
import LanguageDropdown from "./Language";
import Layout from "../../stories/Layout";
import Header from "../../stories/Header";
import Logo from "../../stories/Logo";
import HeaderRightTools from "../../stories/HeaderRightTools";
import useUserStore from "../../stores/userStore";
import NotificationDrawer from "../../stories/NotificationDrawer";
import { styled } from "styled-components";
import Bell from "../../stories/assets/bell.svg";
import Mail from "../../stories/assets/mail.svg";

const Icon = styled.img`
  cursor: pointer;
  width: 18px;
`;

const IconContainer = styled.div`
  position: relative;
`;

const NotificationIndicator = styled.span`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 10px;
  height: 10px;
  border: 1px solid var(--color-container-card-default);
  background: #0cb14e;
  border-radius: 50%;
`;

const NotificationIndicatorMessage = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  border: 1px solid var(--color-container-card-default);
  background: #0cb14e;
  border-radius: 50%;
`;

export default function HeaderAndSidebar({ navlinks, children }) {
  const { user, recent_activities_count, chats_count, gamification } =
    useUserStore((state) => state.user);

  return (
    <Layout
      header={
        <Header
          leftC={<Logo />}
          rightC={
            <HeaderRightTools
              hasNotification={!!recent_activities_count}
              user={user}
              activityNotification={
                <ActivitiesNotification
                  user_id={user.id}
                  recent_activities_count={recent_activities_count}
                />
              }
              chatNotifications={
                <MessagesNotification
                  user_id={user.id}
                  chats_count={chats_count}
                />
              }
            />
          }
        />
      }
      sidebarContent={<NavbarSimple navlinks={navlinks} />}
      mainContent={children}
    />
  );
}

const play = () => {
  const notificationSound = new Audio("/notification.mp3");
  notificationSound.play();
};

const GamificationBar = ({ gamification, user_id }) => {
  const [userGamification, setUserGamification] = useState(gamification);
  useEffect(() => {
    const subscription = App.subscriptions.create(
      { channel: "GamificationChannel", user_id },
      {
        connected() {
          console.log("Successfully connected to Notifications Channel");
        },
        disconnected() {
          console.log("Disconnected from Notifications Channel");
        },
        received(data) {
          console.log("New notification received:", data);
          setUserGamification(data);
          play();
        },
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  return (
    <div
      className="flex w-[200px] my-auto h-4 bg-gray-300 rounded-full overflow-hidden"
      role="progressbar"
      aria-valuenow="75"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div
        className="flex flex-col p-2 justify-center rounded-full overflow-hidden bg-gradient-to-r from-green-400 to-blue-500 text-xs text-white text-center whitespace-nowrap transition duration-500"
        style={{
          width: `${
            (userGamification.current_points /
              userGamification.next_level.points_threshold) *
            100
          }%`,
        }}
      >
        {userGamification.current_points}PX
      </div>
    </div>
  );
};

const MessagesNotification = ({ chats_count, user_id }) => {
  const [unread, setUnread] = useState([]);
  const [read, setRead] = useState([]);
  const [newCount, setNewCount] = useState(0);

  useEffect(() => {
    const subscription = App.subscriptions.create(
      { channel: "MessagesChannel", user_id },
      {
        connected() {
          console.log("Successfully connected to Notifications Channel");
        },
        disconnected() {
          console.log("Disconnected from Notifications Channel");
        },
        received(data) {
          console.log("New notification received:", data);
          setUnread((p) => [data, ...p]);
          setNewCount((p) => ++p);
          play();
        },
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/api/users/fetch_chats`, "GET");

        if (response.ok) {
          const res = await response.json();
          setUnread(res.unread_chats);
          setRead(res.next_five_chats);
        } else {
          const msg = (await response.json()).error;
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during fetching activities:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <IconContainer>
      {chats_count + newCount ? <NotificationIndicatorMessage /> : <></>}
      <NotificationDrawer
        toggleIcon={<Icon src={Mail} alt="Messages" />}
        readNotifications={read}
        unreadNotifications={unread}
      />
    </IconContainer>
  );
};

const ActivitiesNotification = ({ recent_activities_count, user_id }) => {
  const [unread, setUnread] = useState([]);
  const [read, setRead] = useState([]);
  const [newCount, setNewCount] = useState(0);

  useEffect(() => {
    const subscription = App.subscriptions.create(
      { channel: "UserActivitiesChannel", user_id },
      {
        connected() {
          console.log("Connected to Activities Channel");
        },
        disconnected() {
          console.log("Disconnected from Activities Channel");
        },
        received(data) {
          setUnread((p) => [data, ...p]);
          setNewCount((p) => ++p);
          play();
        },
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/api/users/fetch_activities`, "GET");

        if (response.ok) {
          const res = await response.json();
          setUnread(res.unread_activities);
          setRead(res.next_five_activities);
        } else {
          const msg = (await response.json()).error;
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during fetching activities:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <IconContainer>
      <NotificationDrawer
        toggleIcon={<Icon src={Bell} alt="Notifications" />}
        readNotifications={read}
        unreadNotifications={unread}
      />
      {recent_activities_count + newCount ? <NotificationIndicator /> : <></>}
    </IconContainer>
  );
};
