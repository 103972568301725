
import React, { useState, useEffect, useRef } from "react";
import { Alert, Button, Menu, Stack, Text, Group } from "@mantine/core";
import {
  IconAlertCircle,
  IconBrandGoogleDrive,
  IconFilePlus,
  IconFiles,
  IconStack3,
} from "@tabler/icons-react";
import useGoogleDrive from "./GoogleDriveOperations";
import useDropbox from "./DropboxOperations";
import FileList from "./FilesDisplay";
import useStartupStore from "../../stores/startupStore";
import AddphotoIcon from "../../stories/assets/add-photo.svg";
import styled from "styled-components";

const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  border: 1px dashed var(--color-button-accent);
  background-color: var(--color-background-light);
  border-radius: 4px;
  cursor: pointer;
`;

const UploadIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 16px;
`;

const GoogleDrivePickerAndUploader = ({ appId }) => {
  const [localFiles, setLocalFiles] = useState({});
  const [googleDriveFiles, setGoogleDriveFiles] = useState({});
  const [dropboxFiles, setDropboxFiles] = useState({});
  const [exceededFiles, setExceededFiles] = useState({});
  const [showUploadBox, setShowUploadBox] = useState(true);

  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isDropboxLoading, setIsDropboxLoading] = useState(false);

  const {
    files,
    setFiles,
    selectedProgram: { cid: pageId, sid: index },
    program,
  } = useStartupStore((state) => ({
    files: state.files,
    setFiles: state.setFiles,
    selectedProgram: state.selectedProgram,
    program: state.program,
  }));

  const fileInputRef = useRef(null);

  const clientId =
    "646888565719-k2u21847fotu41ud9eagk3rcs18hk9v5.apps.googleusercontent.com";
  const apiKey = "AIzaSyAyrRvP9x6xqL8v3LS82O6AYjvlBvNnsJE";
  const dropboxAppKey = "7u7qdb2r7djclry";
  const dropboxRedirectUri = "http://localhost:3000/dashboard";

  const {
    isGoogleAuthenticated,
    createPicker,
    silentAuthenticate,
    uploadToGoogleDrive,
  } = useGoogleDrive({ clientId, apiKey, appId });

  const {
    authenticateDropbox,
    openDropboxChooser,
    uploadToDropbox,
    isDropboxAuthenticated,
    fetchDropboxThumbnail,
  } = useDropbox(dropboxAppKey, dropboxRedirectUri);

  const getGoogleDrivePreviewUrl = (docId) => {
    return `https://drive.google.com/file/d/${docId}/preview`;
  };

  const getDropboxPreviewUrl = (link) => {
    return link.replace("?dl=0", "?raw=1");
  };

  useEffect(() => {
    const existingFiles = files[pageId]?.[index] || [];
    const local = existingFiles.filter((file) => file.source === "local");
    const drive = existingFiles.filter((file) => file.source === "drive");
    const dropbox = existingFiles.filter((file) => file.source === "dropbox");

    setLocalFiles((prev) => ({ ...prev, [index]: local }));
    setGoogleDriveFiles((prev) => ({ ...prev, [index]: drive }));
    setDropboxFiles((prev) => ({ ...prev, [index]: dropbox }));
    setExceededFiles((prev) => ({ ...prev, [index]: [] }));
  }, [pageId, index]);

  // Calculate total file size for current pageId and index
  const totalFilesSize = [
    ...program.categories[pageId].sub_categories[index].files,
    ...(localFiles[index] || []),
    ...(googleDriveFiles[index] || []),
    ...(dropboxFiles[index] || []),
  ].reduce((acc, file) => acc + (file.size_bytes || file.size || 0), 0);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files).map((file) => {
      const objectURL = URL.createObjectURL(file);
      return {
        file,
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        url: objectURL,
        source: "local",
      };
    });
    const allowedSize = 2 * 1024 * 1024 - totalFilesSize; // 2MB limit per page and index
    const totalSelectedSize = selectedFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );

    if (totalSelectedSize < allowedSize) {
      // The selected files are within allowed size, add to localFiles
      setLocalFiles((prev) => ({
        ...prev,
        [index]: [...(prev[index] || []), ...selectedFiles],
      }));
    } else {
      // The selected files exceed the allowed size, set to exceededFiles
      setExceededFiles((prev) => ({
        ...prev,
        [index]: [...(prev[index] || []), ...selectedFiles],
      }));
    }
    setShowUploadBox(false);
  };

  useEffect(() => {
    const combinedFiles = [
      ...(localFiles[index] || []),
      ...(googleDriveFiles[index] || []),
      ...(dropboxFiles[index] || []),
    ];

    const previousFiles = files[pageId]?.[index] || [];
    if (JSON.stringify(previousFiles) !== JSON.stringify(combinedFiles)) {
      setFiles(pageId, index, combinedFiles);
    }
  }, [localFiles, googleDriveFiles, dropboxFiles, pageId, index]);

  return (
    <>
      {showUploadBox ? (
        <Menu
          transitionProps={{ transition: "rotate-right", duration: 150 }}
          shadow="md"
          width="target"
          withinPortal
          position="top-end"
        >
          <Menu.Target>
            <UploadBox>
              <UploadIcon src={AddphotoIcon} alt="upload" />
              <p style={{ textAlign: "center", fontSize: "0.85rem" }}>
                <strong>Click to upload</strong> or Drag and Drop document
              </p>
              <p style={{ fontSize: "0.75rem" }}>Maximum file size 2MB</p>
            </UploadBox>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Connected apps</Menu.Label>
            {!isGoogleAuthenticated ? (
              <Menu.Item
                icon={<IconBrandGoogleDrive size={14} />}
                onClick={silentAuthenticate}
              >
                <p>Authenticate with Google Drive</p>
              </Menu.Item>
            ) : (
              <Menu.Item
                icon={<IconBrandGoogleDrive size={14} />}
                onClick={() =>
                  createPicker((data) => {
                    if (
                      data[window.google.picker.Response.ACTION] ===
                      window.google.picker.Action.PICKED
                    ) {
                      const pickedFiles = data[
                        window.google.picker.Response.DOCUMENTS
                      ].map((doc) => {
                        const thumbnailUrl = `https://drive.google.com/thumbnail?authuser=0&sz=w320&id=${doc.id}`;

                        return {
                          id: doc.id,
                          name: doc.name,
                          size_bytes: doc.sizeBytes || 0,
                          mimeType: doc.mimeType,
                          icon: doc.iconUrl,
                          url: getGoogleDrivePreviewUrl(doc.id),
                          source: "drive",
                          thumbnail: thumbnailUrl,
                        };
                      });
                      setGoogleDriveFiles((prev) => ({
                        ...prev,
                        [index]: [...(prev[index] || []), ...pickedFiles],
                      }));
                      setShowUploadBox(false); // Hide upload box after selecting files
                    }
                  })
                }
              >
                <p>Choose from Google Drive</p>
              </Menu.Item>
            )}

            {!isDropboxAuthenticated ? (
              <Menu.Item
                icon={<IconStack3 size={14} />}
                onClick={authenticateDropbox}
              >
                <p>Authenticate with Dropbox</p>
              </Menu.Item>
            ) : (
              <Menu.Item
                icon={<IconStack3 size={14} />}
                onClick={() =>
                  openDropboxChooser(async (files) => {
                    const selectedFiles = await Promise.all(
                      files.map(async (file) => {
                        let thumbnailUrl = null;

                        if (file.path_lower) {
                          try {
                            thumbnailUrl = await fetchDropboxThumbnail(
                              file.path_lower
                            );
                          } catch (error) {
                            console.error(
                              "Error fetching thumbnail for Dropbox file:",
                              file.name,
                              error
                            );
                            thumbnailUrl = file.icon; // fallback to icon
                          }
                        } else {
                          thumbnailUrl = file.icon;
                        }

                        return {
                          id: file.id,
                          name: file.name,
                          size_bytes: file.bytes,
                          url: getDropboxPreviewUrl(file.link),
                          source: "dropbox",
                          icon: file.icon,
                          thumbnail: thumbnailUrl || file.icon,
                        };
                      })
                    );

                    setDropboxFiles((prev) => ({
                      ...prev,
                      [index]: [...(prev[index] || []), ...selectedFiles],
                    }));
                    setShowUploadBox(false); // Hide upload box after selecting files
                  })
                }
              >
                <p>Choose from Dropbox</p>
              </Menu.Item>
            )}

            <Menu.Divider />

            <Menu.Item
              icon={<IconFilePlus size={14} />}
              onClick={() => fileInputRef.current.click()}
            >
              <p>Upload From Computer</p>
            </Menu.Item>
            {(!!program.categories[pageId].sub_categories[index].files.length ||
              !!program.categories[pageId].sub_categories[index].file_records
                .length ||
              !!(files[pageId]?.[index] || []).length) && (
              <Menu.Item
                icon={<IconFiles size={14} />}
                onClick={() => setShowUploadBox(false)}
              >
                <p>View Uploaded Files</p>
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      ) : (
        <>
          {/* Display exceeded files and alerts */}
          {exceededFiles[index]?.length !== 0 ? (
            <>
              <FileList fileRecords={exceededFiles[index]} />
              <Alert icon={<IconAlertCircle size="1rem" />} color="dark">
                <Stack>
                  <Text>
                    The selected files exceed the 2MB limit. Please store them
                    in one of your cloud storage options below.
                  </Text>
                  <Group>
                    {isGoogleAuthenticated ? (
                      <Button
                        onClick={async () => {
                          setIsGoogleLoading(true);
                          const uploadedFiles = await uploadToGoogleDrive(
                            exceededFiles[index]
                          );
                          setGoogleDriveFiles((prev) => ({
                            ...prev,
                            [index]: [...(prev[index] || []), ...uploadedFiles],
                          }));
                          if (uploadedFiles.length)
                            setExceededFiles((prev) => ({
                              ...prev,
                              [index]: [],
                            }));
                          setIsGoogleLoading(false);
                        }}
                        loading={isGoogleLoading}
                        leftIcon={<IconBrandGoogleDrive size={14} />}
                        variant="default"
                      >
                        Save to Google Drive
                      </Button>
                    ) : (
                      <Button
                        onClick={silentAuthenticate}
                        leftIcon={<IconBrandGoogleDrive size={14} />}
                        variant="default"
                      >
                        Auth with Google Drive
                      </Button>
                    )}

                    {isDropboxAuthenticated ? (
                      <Button
                        onClick={async () => {
                          setIsDropboxLoading(true);
                          const uploadedFiles = await uploadToDropbox(
                            exceededFiles[index]
                          );

                          setDropboxFiles((prev) => ({
                            ...prev,
                            [index]: [...(prev[index] || []), ...uploadedFiles],
                          }));
                          if (uploadedFiles.length)
                            setExceededFiles((prev) => ({
                              ...prev,
                              [index]: [],
                            }));
                          setIsDropboxLoading(false);
                        }}
                        loading={isDropboxLoading}
                        leftIcon={<IconStack3 size={14} />}
                        variant="default"
                      >
                        Save to Dropbox
                      </Button>
                    ) : (
                      <Button
                        onClick={authenticateDropbox}
                        leftIcon={<IconStack3 size={14} />}
                        variant="default"
                      >
                        Auth with Dropbox
                      </Button>
                    )}
                  </Group>
                </Stack>
              </Alert>
            </>
          ) : (
            <div>
              <Button
                onClick={() => setShowUploadBox(true)}
                variant="outline"
                compact
              >
                Add More Files
              </Button>
              <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                <FileList
                  fileRecords={[
                    ...program.categories[pageId].sub_categories[index].files,
                    ...program.categories[pageId].sub_categories[index]
                      .file_records,
                    ...(localFiles[index] || []),
                    ...(googleDriveFiles[index] || []),
                    ...(dropboxFiles[index] || []),
                  ]}
                />
              </div>
            </div>
          )}
        </>
      )}

      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple
      />
    </>
  );
};

export default GoogleDrivePickerAndUploader;
